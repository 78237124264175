/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ya ha salido la versión 0.8.2 de Compiz en los repositorios de Debian, estaba esperando esta versión porque es la primera que soporta KDE4, aunque hay mucho usuario de KDE4 que defiende los efectos que este trae por defecto, pero a mi no me acaban de convencer ademas de que son demasiado pesados para mi gráfica, creo que aun están por pulir."), "\n", React.createElement(_components.p, null, "Para instalar compiz en KDE tenemos que instalar el paquete compiz-kde, porque el paquete compiz está pensado para gnome."), "\n", React.createElement(_components.p, null, "Otra cosa que ha cambiado es que yo antes utilizaba emerald como decorador de ventanas y ahora con el pluggin Decorador de Ventanas podemos utilizar el propio de KDE4."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
